import { Fragment, Suspense, useEffect, useRef, useState } from 'react';
import { NavLink, BrowserRouter as Router, Switch, Route, RouteProps, useHistory } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Menu, Transition } from '@headlessui/react'
import { LoadingView, PageState, PageStatus, bannerHelper, callGetApi } from './stdlib-form';
import { PortalPageDetails, PortalRouterPageObj, AppMessage, AppRedirectAction } from './stdlib-appmodels';
import { ActionItemClickHandler, ActionItemRenderOptions, RenderActionItems } from './stdlib-ui';
import toast, { Toaster } from 'react-hot-toast';


export const combinePageDetails: (base: PortalPageDetails, child: PortalPageDetails) => PortalPageDetails = (base, child) => {
    return {
        ...base,
        child: child
    };
}

export interface RedirectHandlerOptions {
    parent: (redirect: AppRedirectAction) => void,
    //newGuidUrlFunc?: (newGuid: string) => string,
    parentUrl?: string;
    refreshChildFunc?: () => void,

}

export const redirectHandler: (options: RedirectHandlerOptions) => (redirect: AppRedirectAction) => void = (options) => {
    //convert all actions to fixed url redirect action passed down the line
    return (childAction) => {
        if (childAction.redirectAction === "redirect") {
            options.parent(childAction);
            return;
        }
       
        if (childAction.redirectAction === "parent" || childAction.redirectAction === "refresh-parent") {
            if (!options.parentUrl) {
                console.log("parentUrl not set cannot redirect");
                return;  
            } 
            options.parent({ redirectAction: "redirect", redirectUrl: options.parentUrl, toast: childAction.toast })
            return;
        }
        if (childAction.redirectAction === "refresh-self") {
            if (!options.refreshChildFunc) {
                console.log("refreshChildFunc not set cannot redirect");
                return;
            } 
            options.refreshChildFunc();
            return;
        }
    }
};



// const oldMainMenu = <T,>(props: AppProps<T>, appData: T) => <Menu as="div" className="relative inline-block text-left">
//     <div>
//         <Menu.Button className="visible lg:hidden inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-black bg-opacity-20 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
//             <svg className="fill-current text-gray-900" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
//                 <title>
//                     <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
//                         <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
//                     </svg>
//                 </title>
//                 <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
//             </svg>
//         </Menu.Button>
//     </div>
//     <Transition
//         as={Fragment}
//         enter="transition ease-out duration-100"
//         enterFrom="transform opacity-0 scale-95"
//         enterTo="transform opacity-100 scale-100"
//         leave="transition ease-in duration-75"
//         leaveFrom="transform opacity-100 scale-100"
//         leaveTo="transform opacity-0 scale-95"
//     >
//         <Menu.Items className="absolute z-10 left-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
//             <div className="px-1 py-1 ">
//                 {props.routes.filter(x => props.routeFilterFunc(appData, x)).map((x, i) =>
//                     <Menu.Item key={i}>
//                         {({ active }) => (
//                             <NavLink onClick={()=> props.se} className="sidebar-button" to={x.path}><div>{x.name}</div></NavLink>
//                         )}
//                     </Menu.Item>
//                 )}
//             </div>

//         </Menu.Items>
//     </Transition>
// </Menu>;

// const RenderActionItem: (item: ActionItem, key: number, setMenuOpen?: (value: boolean) => void, options?: ActionItemRenderOptions) => JSX.Element = (item, key, setMenuOpen, options) => {

//     if (item.kind === "link") {


//         return <NavLink onClick={() => setMenuOpen && setMenuOpen(!options?.shouldCloseMenu)} key={key} activeClassName={item.className ?? options?.linkActiveClassName} className={options?.linkClassName} to={item.to}><div>{item.title}{item.badge === "new" && <div>NEW</div>}</div></NavLink>
//     }

//     if (item.kind === "button") {
//         return <button key={key} type={item.type ?? "button"} form={item.formId} className={item.className ?? options?.buttonClassName} onClick={item.action} disabled={item.disabled}>{item.title}</button>
//     }

//     if (item.kind === "href") {
//         return <a key={key} className={item.className ?? options?.hrefClassName} href={item.href}>{item.title}</a>
//     }


//     return <></>;
// }

// const RenderActionItems: (items: ActionItem[], setMenuOpen?: (value: boolean) => void, options?: ActionItemRenderOptions) => JSX.Element = (items, setMenuOpen, options) => {
//     return <>
//         {Array.isArray(items) && items.map((x, i) => RenderActionItem(x, i, setMenuOpen, options))}
//     </>;
// }

const renderArray: <T>(array: T[] | undefined) => boolean = array => Array.isArray(array) && array.length > 0;

const renderBar: (details: PortalPageDetails) => boolean = details => renderArray(details.actions);

const renderOptions: ActionItemRenderOptions = {
    linkActiveClassName: "active",
    linkClassName: "sidebar-button",
    buttonClassName: "border text-gray-500 p-1 m-1 rounded text-sm hover:bg-gray-200",
    hrefClassName: "p-1 m-1 ml-3 link text-sm"
}



const MainMenu = <T,>(props: { appData: T, appProps: AppProps<T>, pageDetails: PortalPageDetails, setMenuOpen: (open: boolean) => void, lockMenu: boolean, setLockMenu: (lock: boolean) => void }) => {

    const onClick: ActionItemClickHandler = (item) => {
        if (item.shouldCloseMenu) props.setMenuOpen(false);
    };

    const drawPageDetails: (index: number, details: PortalPageDetails, onClick: ActionItemClickHandler) => JSX.Element = (index, details, onClick) => {

        const isLastChild = !details.child?.actions;
        const lastId = isLastChild ? "menu-last-item" : "";

        return <> {renderBar(details) &&
            <div className="flex flex-col" {...lastId && { id: lastId }}>
                <div className="text-gray-500 p-1 text-sm m-1 font-bold">
                    {details.title}
                </div>
                {details.actions &&
                    <div className='flex flex-col'>
                        {RenderActionItems(details.actions, renderOptions, onClick)}
                    </div>}
            </div>}
            {details.child && drawPageDetails(index + 1, details.child, onClick)}
        </>;
    }

    return <div className="stdlib-menu">
        <div className="flex-grow flex flex-row flex-wrap overflow-y-auto">
            <div className="flex flex-col">
                {props.appProps.routes.filter(x => props.appProps.routeFilterFunc(props.appData, x)).map((x, i) =>
                    <NavLink onClick={() => x.shouldCloseMenu && props.setMenuOpen(false)} exact={x.exact} key={i} activeClassName="active" className="sidebar-button" to={x.path}><div>{x.name}</div></NavLink>
                )}

            </div>
            {props.pageDetails && drawPageDetails(0, props.pageDetails, onClick)}


        </div>
        <div className='m-2'>
            <button className='btn stdlib-header-button' onClick={() => props.setLockMenu(!props.lockMenu)}>
                {props.lockMenu && <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
                </svg>
                }
                {!props.lockMenu && <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 10.5V6.75a4.5 4.5 0 119 0v3.75M3.75 21.75h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H3.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
                </svg>
                }
            </button>
        </div>
    </div>;

}

const MainContent = <T,>(props: { appData: T, appProps: AppProps<T> }) => {

    const history = useHistory();


    const lockMenuKey = "lock-menu";
    const lockedByDefault = () => {
        let value = window.innerWidth > 1000;
        const lsv = localStorage.getItem(lockMenuKey);

        if (lsv !== null) {
            value = lsv === "yes";
        }
        return value;
    }


    const [menuOpenState, setMenuOpen] = useState<boolean>(lockedByDefault);
    const [menuLocked, setMenuLockedState] = useState<boolean>(lockedByDefault);
    const [pageDetails, setPageDetailsState] = useState<PortalPageDetails>({} as PortalPageDetails);
    const [redirectUrl, setRedirectUrl] = useState<string>();

    const menuOpen = menuLocked || menuOpenState;
    const showToast = (appMsg: AppMessage) => toast(appMsg.message);

    const setPageDetails = (details: PortalPageDetails) => {
        if (JSON.stringify(details) === JSON.stringify(pageDetails)) return;
        setPageDetailsState(details);
    }

    const setMenuLocked = (value: boolean) => {
        localStorage.setItem(lockMenuKey, value ? "yes" : "no");
        setMenuLockedState(value);
    }

    useEffect(() => {
        const element = document.getElementById("menu-last-item");
        if (!element) return;
        const htmlElement = element as HTMLElement;
        htmlElement.scrollIntoView();
    }, [menuOpen, pageDetails]);

    useEffect(() => { 
        if (!redirectUrl) return;
        history.push(redirectUrl);
    },[redirectUrl]);

    const doMenuClose = (open: boolean) => {
        if (menuLocked) return;
        setMenuOpen(open);
    }

    const doRedirect = (redirect: AppRedirectAction) => {
        
        if (redirect.toast) showToast(redirect.toast);
        if (redirect.redirectAction !== "redirect" || !redirect.redirectUrl) {
            console.log(`Cannot redirect because redirectAction not valid here or '${redirect.redirectUrl}' is empty`)
            return;
        } 
        setMenuOpen(false);
        setRedirectUrl(redirect.redirectUrl);
        
    };
    //{mainMenu(props.appProps, props.appData)} 
    return (<div className="flex flex-col flex-grow overflow-hidden bg-stdlib-panel-bg">
        <div className="stdlib-header">
            {!menuLocked &&
                <button className="btn stdlib-lock-button" onClick={() => setMenuOpen(!menuOpen)}>
                    {menuOpen &&
                        <svg  fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                    } 

                    {!menuOpen &&
                        <svg  fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                        </svg>
                   } 
                </button>}
            <img src={props.appProps.headerLogo} alt="Portal" />
        </div>
        <div className="flex flex-grow flex-col lg:flex-row lg:overflow-y-hidden">
            {menuOpen && <MainMenu {...props} pageDetails={pageDetails} setMenuOpen={doMenuClose} lockMenu={menuLocked} setLockMenu={setMenuLocked} />}
            <div className='flex flex-grow justify-center lg:overflow-y-hidden stdlib-background'>
                <Suspense fallback={LoadingView}>
                    <Switch>
                        {props.appProps.routes.map((route, idx) => {
                            return route.component && (
                                <Route
                                    key={idx}
                                    path={route.path}
                                    exact={route.exact}
                                    render={(p: any) => <route.component {...p} setPageDetails={setPageDetails} showToast={showToast} appData={props.appData}  doRedirect={doRedirect} />
                                    } />
                            )
                        })}

                    </Switch>
                </Suspense>
            </div>
        </div>
        <div className="stdlib-footer">
            Copyright &copy; Caicos Consulting
        </div>

    </div >);
}

export interface AppProps<TAppData> {
    appDataApiUrl: string,
    //appObject: TAppObj;
    headerLogo: string,
    defaultTitle: string,
    routes: PortalRouterPageObj<TAppData, {}, {}>[], routeFilterFunc: (appData: TAppData, route: PortalRouterPageObj<TAppData, {}, {}>) => boolean
}

export const stdlibApp = <T,>(props: AppProps<T>) => {
    return () => {

        const [pageState, setPageState] = useState<PageState>(PageState.NotLoaded);
        const [pageStatus, setPageStatus] = useState<PageStatus>(PageStatus.Idle);
        const [appData, setAppData] = useState<T>({} as T);
        const { statusBanner, contentNotLoaded } = bannerHelper(pageState, pageStatus);
        useEffect(() => callGetApi<T>(props.appDataApiUrl, setPageState, setPageStatus, setAppData), []);
        var contentLoaded = !contentNotLoaded();

        return (
            <>
                <HelmetProvider>
                    <Router>
                        <Helmet>
                            <title>{props.defaultTitle}</title>
                        </Helmet>
                        <Toaster />
                        <Route exact path='/sign-in' component={(props: RouteProps) => { window.location.href = `/sign-in${props.location?.search}`; return null; }} />
                        {contentLoaded && <MainContent appData={appData} appProps={props} />}
                        {!contentLoaded && statusBanner()}

                    </Router>
                </HelmetProvider>
            </>
        );
    }
}
