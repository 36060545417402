import { Guid } from "../stdlib/stdlib-models";

export const DashApiUrl = "/api/dashboard";
export const PermissionsApiUrl = "/api/dashboard/permissions";
export const AccountSettingsApi = "/api/account-settings";
export const SignOutHereApiUrl = "/api/sign-out";
export const SignOutEverywhereApiUrl = "/api/sign-out-everywhere";




export const OpWeeklyCalendarApiUrl: (businessGuid: string, startDate: Date) => string = (_,startDate) => `/api/o/calendar?startDate=${startDate.toISOString()}&period=week`;
export const OpMonthlyCalendarApiUrl: (businessGuid: string, startDate: Date) => string = (_, startDate) => `/api/o/calendar?startDate=${startDate.toISOString()}&period=month`;
//export const OpDeleteBookingApiUrl: (bookingGuid: Guid) => Guid = bookingGuid => `/api/o/booking/${bookingGuid}/delete`;

export const OpUpcomingBookingsApiUrl = (_: string)=> "/api/o/upcomingbookings";
export const OpPastBookingsApiUrl = (_: string) => "/api/o/pastbookings";


export const OpListProductsApiUrl = "/api/o/products";
export const OpAddProductApiUrl = "/api/o/addproduct";
export const OpProductSummaryApiUrl: (productGuid: Guid) => Guid = productGuid => `/api/o/product/${productGuid}/summary`;
export const OpProductBasicsApiUrl: (productGuid: Guid) => Guid = productGuid => `/api/o/product/${productGuid}/basics`;
export const OpDeleteProductApiUrl: (productGuid: Guid) => Guid = productGuid => `/api/o/product/${productGuid}/delete`;
export const OpResetProductCalendarApiUrl: (productGuid: Guid) => Guid = productGuid => `/api/o/product/${productGuid}/resetcalendar`;

export const OpNewBookingStateApiUrl = (_: string, date: string) => `/api/o/newbookingstate?date=${date}`;
export const OpNewProductBookingApiUrl = (_: string, productGuid: string) => `/api/o/newproductbooking?productGuid=${productGuid}`;
export const OpNewCustomBookingApiUrl = (_: string) => "/api/o/newcustombooking";
export const OpGetBookingApiUrl: (businessGuid: Guid, bookingGuid: Guid) => Guid = (_,bookingGuid) => `/api/o/b/${bookingGuid}`;
export const OpCancelBookingApiUrl: (businessGuid: Guid, bookingGuid: Guid) => Guid = (_, bookingGuid) => `/api/o/b/${bookingGuid}/cancel`;
export const OpDeleteBookingApiUrl: (businessGuid: Guid, bookingGuid: Guid) => Guid = (_, bookingGuid) => `/api/o/b/${bookingGuid}/delete`;
export const OpCheckInBookingApiUrl: (businessGuid: Guid, bookingGuid: Guid) => Guid = (_, bookingGuid) => `/api/o/b/${bookingGuid}/checkin`;
export const OpSignWaiverBookingApiUrl: (businessGuid: Guid, bookingGuid: Guid) => Guid = (_, bookingGuid) => `/api/o/b/${bookingGuid}/signwaiver`;
export const OpBookingCardPaymentApiUrl: (businessGuid: Guid, bookingGuid: Guid) => Guid = (_, bookingGuid) => `/api/o/b/${bookingGuid}/cardpayment`;
export const OpBookingOtherPaymentApiUrl: (businessGuid: Guid, bookingGuid: Guid) => Guid = (_, bookingGuid) => `/api/o/b/${bookingGuid}/otherpayment`;
export const OpMoveBookingDateStateApiUrl: (businessGuid: Guid, bookingGuid: Guid) => Guid = (_, bookingGuid) => `/api/o/b/${bookingGuid}/movedatestate`;
export const OpMoveBookingAssetStateApiUrl: (businessGuid: Guid, bookingGuid: Guid) => Guid = (_, bookingGuid) => `/api/o/b/${bookingGuid}/moveassetstate`;
export const OpMoveBookingDateApiUrl: (businessGuid: Guid, bookingGuid: Guid) => Guid = (_, bookingGuid) => `/api/o/b/${bookingGuid}/movedate`;
export const OpMoveBookingAssetApiUrl: (businessGuid: Guid, bookingGuid: Guid) => Guid = (_, bookingGuid) => `/api/o/b/${bookingGuid}/moveasset`;
export const OpEditBookingApiUrl: (businessGuid: Guid, bookingGuid: Guid) => Guid = (_, bookingGuid) => `/api/o/b/${bookingGuid}/edit`;
export const OpChangeBookingGuestsApiUrl: (businessGuid: Guid, bookingGuid: Guid) => Guid = (_, bookingGuid) => `/api/o/b/${bookingGuid}/changeguests`;
export const OpBookingAnalyticsApiUrl: (businessGuid: Guid, bookingGuid: Guid) => Guid = (_, bookingGuid) => `/api/o/b/${bookingGuid}/analytics`;

//OpBookingAnalyticsApiUrl


export const AfWeeklyCalendarApiUrl: (startDate: Date) => string = startDate => `/api/a/calendar?startDate=${startDate.toISOString()}&period=week`;
export const AfProductState: (productGuid: Guid, startDate: Date) => string = (productGuid, startDate) => `/api/a/productstate?productGuid=${productGuid}&date=${startDate.toISOString()}`;
export const AfGetBooking: (bookingGuid: Guid) => string = bookingGuid => `/api/a/b/${bookingGuid}`;
export const AfEditBooking: (bookingGuid: Guid) => string = bookingGuid => `/api/a/b/${bookingGuid}/edit`;
//export const AfBookingCardPaymentApiUrl: (bookingGuid: Guid) => Guid = bookingGuid => `/api/a/b/${bookingGuid}/cardpayment`;
export const AfNewBookingCardApiUrl: (businessGuid: Guid) => string = (businessGuid) => `/api/a/newbooking?businessGuid=${businessGuid}`;
export const AfNewBookingReserveApiUrl: (businessGuid: Guid) => string = (businessGuid) => `/api/a/newbooking?businessGuid=${businessGuid}`;

export const OpListPromotionsApiUrl = "/api/o/promotions";
export const OpAddPromotionApiUrl = "/api/o/addpromotion";
export const OpDeletePromotionApiUrl: (promotionGuid: Guid) => Guid = promotionGuid => `/api/o/promotion/${promotionGuid}/delete`;
export const OpPromotionApiUrl: (promotionGuid: Guid) => Guid = promotionGuid => `/api/o/promotion/${promotionGuid}`;

export const OpBlocksApiUrl = "/api/o/blocks";
export const OpAddBlockApiUrl = "/api/o/addblock";
export const OpDeleteBlockApiUrl: (blockGuid: Guid) => Guid = blockGuid => `/api/o/block/${blockGuid}/delete`;
export const OpBlockApiUrl: (blockGuid: Guid) => Guid = blockGuid => `/api/o/block/${blockGuid}`;