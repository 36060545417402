import React from 'react';
//import { BusinessAppData } from './components/models';
import { PortalRouterPageObj } from './stdlib/stdlib-appmodels';
 import { PermissionsApiUrl } from './components/apiUrls';
 import headerLogo from './portal-header.png';
import { stdlibApp } from './stdlib/stdlib-app';

const DashPage = React.lazy(() => import('./pages/DashPage'));
const OpAnalyticsPage = React.lazy(() => import('./pages/operator/AnalyticsPage'));
const OpMonthlyCalendarPage = React.lazy(() => import('./pages/operator/MonthlyCalendarPage'));
const OpWeeklyCalendarPage = React.lazy(() => import('./pages/operator/WeeklyCalendarPage'));
const OpPromotionsPage = React.lazy(() => import('./pages/operator/PromotionsPage'));
const OpProductsIndexPage = React.lazy(() => import('./pages/operator/ProductsIndexPage'));
const OpReportsPage = React.lazy(() => import('./pages/operator/ReportsPage'));
const OpUpcomingBookingsPage = React.lazy(() => import('./pages/operator/UpcomingBookingsTablePage'));
const OpPastBookingsPage = React.lazy(() => import('./pages/operator/PastBookingsTablePage'));
const AccountSettingsPage = React.lazy(() => import('./pages/AccountSettingsPage'));
const SignOutPage = React.lazy(() => import('./pages/SignOutPage'));

const AfWeeklyCalendarPage = React.lazy(() => import('./pages/affiliate/WeeklyCalendarPage'));

export const routes: PortalRouterPageObj<BusinessPermissions>[] = [
  { path: '/', exact: true, name: 'Dashboard', component: DashPage, shouldCloseMenu: true },

  { path: '/o/weekly-calendar', name: 'Weekly Calendar', component: OpWeeklyCalendarPage, shouldCloseMenu: true },
  { path: '/o/monthly-calendar', name: 'Monthly Calendar', component: OpMonthlyCalendarPage, shouldCloseMenu: true },
  { path: '/a/weekly-calendar', name: 'Weekly Calendar', component: AfWeeklyCalendarPage, shouldCloseMenu: true },

  { path: '/o/upcoming-bookings', name: 'Upcoming Bookings', component: OpUpcomingBookingsPage, shouldCloseMenu: true },
  { path: '/o/past-bookings', name: 'Past Bookings', component: OpPastBookingsPage, shouldCloseMenu: true },
  { path: '/o/products', name: 'Products', component: OpProductsIndexPage, shouldCloseMenu: true },
  { path: '/o/promotions', name: 'Promotions', component: OpPromotionsPage, shouldCloseMenu: true },
  { path: '/o/analytics', name: 'Analytics', component: OpAnalyticsPage, shouldCloseMenu: true },
  { path: '/o/reports', name: 'Reports', component: OpReportsPage, shouldCloseMenu: true },
  { path: '/account-settings', name: 'Account Settings', component: AccountSettingsPage, shouldCloseMenu: true },
  { path: '/sign-out', name: 'Sign Out', component: SignOutPage, shouldCloseMenu: true },
];

export interface BusinessPermissions {
  affiliateEnabled: boolean;
  operatorEnabled: boolean;
  referralEnabled: boolean;
  hasUserPermission: boolean;
  cardPaymentsEnabled: boolean;
  businessGuid: string;
}

const routeFilterFunc = (appData: BusinessPermissions, route: PortalRouterPageObj<BusinessPermissions, {}, {}>) => {

  //console.log(appData);

  if (!appData.operatorEnabled) {
    if (route.component === OpWeeklyCalendarPage) return false;
    if (route.component === OpMonthlyCalendarPage) return false;
    if (route.component === OpPastBookingsPage) return false;
    if (route.component === OpUpcomingBookingsPage) return false;
  }

  if (!appData.affiliateEnabled) {
    if (route.component === AfWeeklyCalendarPage) return false;  
  }

  if (route.component === OpAnalyticsPage) return false;
  if (route.component === OpReportsPage) return false;
  if (route.component === OpPromotionsPage) return false;
  if (route.component === OpProductsIndexPage) return false;

  

  return true;
}

export default stdlibApp<BusinessPermissions>({appDataApiUrl: PermissionsApiUrl, routes, routeFilterFunc, headerLogo, defaultTitle: "Book.tc Portal" })



// import { Menu, Transition } from '@headlessui/react';
// import React, { Fragment, Suspense, useEffect, useState } from 'react';
// //import { Helmet } from 'react-helmet';
// import { Helmet, HelmetProvider } from 'react-helmet-async';
// import { Switch, Route, BrowserRouter as Router, RouteProps } from 'react-router-dom';
// import { NavLink } from 'react-router-dom';
// import { PermissionsApiUrl } from './components/apiUrls';
// import { routes } from './routes';
// import headerLogo from './portal-header.png';
// import headerLogo2x from './portal-header-2x.png';
// import { LoadingView, PageState, PageStatus, bannerHelper, callGetApi } from './stdlib/stdlib-form';



// const operatorMobileMenu = (permissions: BusinessPermissions) => <>
//   {permissions.hasUserPermission &&
//     <>
//       <Menu.Item>
//         {({ active }) => (
//           <NavLink className="sidebar-button" to="/o/monthly-calendar"><div >Monthly Calendar</div></NavLink>
//         )}
//       </Menu.Item>
//       <Menu.Item>
//         {({ active }) => (
//           <NavLink className="sidebar-button" to="/o/weekly-calendar"><div >Weekly Calendar</div></NavLink>
//         )}
//       </Menu.Item>
//       <Menu.Item>
//         {({ active }) => (
//           <NavLink className="sidebar-button" to="/o/upcoming-bookings"><div >Upcoming Bookings</div></NavLink>
//         )}
//       </Menu.Item>
//       <Menu.Item>
//         {({ active }) => (
//           <NavLink className="sidebar-button" to="/o/past-bookings"><div >Past Bookings</div></NavLink>
//         )}
//       </Menu.Item>
//       <Menu.Item>
//         {({ active }) => (
//           <NavLink className="sidebar-button" to="/o/promotions"><div >Promotions</div></NavLink>
//         )}
//       </Menu.Item>

//       <Menu.Item>
//         {({ active }) => (
//           <NavLink className="sidebar-button" to="/o/products"><div >Products</div></NavLink>
//         )}
//       </Menu.Item>
//       <Menu.Item>
//         {({ active }) => (
//           <NavLink className="sidebar-button" to="/o/reports"><div >Reports</div></NavLink>
//         )}
//       </Menu.Item>
//       <Menu.Item>
//         {({ active }) => (
//           <NavLink className="sidebar-button" to="/o/analytics"><div >Analytics</div></NavLink>
//         )}
//       </Menu.Item>
//     </>
//   }
// </>;

// const operatorMainMenu = (permissions: BusinessPermissions) => <>
//   {permissions.hasUserPermission &&
//     <><NavLink activeClassName="active" className="sidebar-button" to="/o/monthly-calendar"><div >Monthly Calendar</div></NavLink>
//       <NavLink activeClassName="active" className="sidebar-button" to="/o/weekly-calendar"><div >Weekly Calendar</div></NavLink>
//       <NavLink activeClassName="active" className="sidebar-button" to="/o/upcoming-bookings"><div >Upcoming Bookings</div></NavLink>
//       <NavLink activeClassName="active" className="sidebar-button" to="/o/past-bookings"><div >Past Bookings</div></NavLink>
//       <NavLink activeClassName="active" className="sidebar-button" to="/o/promotions"><div >Promotions</div></NavLink>
//       <NavLink activeClassName="active" className="sidebar-button" to="/o/products"><div >Products</div></NavLink>
//       <NavLink activeClassName="active" className="sidebar-button" to="/o/reports"><div >Reports</div></NavLink>
//       <NavLink activeClassName="active" className="sidebar-button" to="/o/analytics"><div >Analytics</div></NavLink>

//     </>
//   }
// </>;

// const affiliateMobileMenu = (permissions: BusinessPermissions) => <>
//   {permissions.hasUserPermission &&
//     <Menu.Item>
//       {({ active }) => (
//         <NavLink className="sidebar-button" to="/a/weekly-calendar"><div >Product Calendar</div></NavLink>
//       )}
//     </Menu.Item>
//   }
// </>;

// const affiliateMainMenu = (permissions: BusinessPermissions) => <>
//   {permissions.hasUserPermission && <NavLink activeClassName="active" className="sidebar-button" to="/a/weekly-calendar"><div >Product Calendar</div></NavLink>}
// </>;


// const mainMenu = (permissions: BusinessPermissions) => <Menu as="div" className="relative inline-block text-left">
//   <div>
//     <Menu.Button className="visible lg:hidden inline-flex justify-center w-full px-2 py-2 text-sm font-medium text-white bg-black bg-opacity-20 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
//       <svg className="fill-current text-gray-900" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
//         <title>menu</title>
//         <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
//       </svg>
//     </Menu.Button>
//   </div>
//   <Transition
//     as={Fragment}
//     enter="transition ease-out duration-100"
//     enterFrom="transform opacity-0 scale-95"
//     enterTo="transform opacity-100 scale-100"
//     leave="transition ease-in duration-75"
//     leaveFrom="transform opacity-100 scale-100"
//     leaveTo="transform opacity-0 scale-95"
//   >
//     <Menu.Items className="absolute z-10 left-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
//       <div className="px-1 py-1 ">
//         <Menu.Item>
//           {({ active }) => (
//             <NavLink exact className="sidebar-button" to="/"><div >Dashboard</div></NavLink>
//           )}
//         </Menu.Item>
//         {permissions.operatorEnabled && operatorMobileMenu(permissions)}
//         {permissions.affiliateEnabled && affiliateMobileMenu(permissions)}

//         <Menu.Item>
//           {({ active }) => (
//             <NavLink className="sidebar-button" to="/account-settings"><div >Account Settings</div></NavLink>
//           )}
//         </Menu.Item>
//         <Menu.Item>
//           {({ active }) => (
//             <NavLink className="sidebar-button" to="/sign-out"><div >Sign Out</div></NavLink>
//           )}
//         </Menu.Item>
//       </div>
//     </Menu.Items>
//   </Transition>
// </Menu>;
// //style={{height:"-webkit-fill-available"}}
// const mainContent = (permissions: BusinessPermissions) => {
//   return (<div className="flex flex-col overflow-hidden app-height"  >
//     <div className="flex-grow-0 p-4 flex flex-row items-center gap-4" style={{ background: "rgb(255, 255, 255)" }}>
//       {mainMenu(permissions)}
//       <picture>
//         <source srcSet={`${headerLogo}, ${headerLogo2x} 2x`} />
//         <img src={headerLogo} alt="Book.tc" />
//       </picture>

//     </div>
//     <div className="flex flex-grow flex-col lg:flex-row overflow-hidden ">
//       <div className="hidden lg:block flex-grow-0 bg-gray-300" style={{ background: "rgb(255, 255, 255)", borderTop: "1px solid rgb(216, 219, 224)" }}>
//         <div className="flex flex-col">
//           <NavLink exact activeClassName="active" className="sidebar-button" to="/"><div >Dashboard</div></NavLink>
//           {permissions.operatorEnabled && operatorMainMenu(permissions)}
//           {permissions.affiliateEnabled && affiliateMainMenu(permissions)}
//           <NavLink activeClassName="active" className="sidebar-button" to="/account-settings"><div >Account Settings</div></NavLink>
//           <NavLink activeClassName="active" className="sidebar-button" to="/sign-out"><div >Sign Out</div></NavLink>
//         </div>
//       </div>

//       <Suspense fallback={LoadingView}>
//         <Switch>
//           {routes.map((route: any, idx) => {
//             return route.component && (
//               <Route
//                 key={idx}
//                 path={route.path}
//                 exact={route.exact}
//                 render={props => <route.component permissions={permissions} cardPaymentsEnabled={permissions.cardPaymentsEnabled} {...props} />
//                 } />
//             )
//           })}

//         </Switch>
//       </Suspense>

//     </div>
//     <div className="hidden md:block flex-grow-0 p-2 text-xs" style={{ color: "rgb(60, 75, 100)", background: "rgb(235, 237, 239)", borderTop: "1px solid rgb(216, 219, 224)" }}>
//       Copyright &copy; Caicos Consulting
//     </div>

//   </div>);
// }

// const App = () => {
//   const [pageState, setPageState] = useState<PageState>(PageState.NotLoaded);
//   const [pageStatus, setPageStatus] = useState<PageStatus>(PageStatus.Idle);
//   const [permissions, setPermissions] = useState<BusinessPermissions>({} as BusinessPermissions);
//   const { statusBanner, contentNotLoaded } = bannerHelper(pageState, pageStatus);
//   useEffect(() => callGetApi<BusinessPermissions>(PermissionsApiUrl, setPageState, setPageStatus, setPermissions), []);

//   var contentLoaded = !contentNotLoaded();

//   return (
//     <>
//       <HelmetProvider>
//         <Router>
//           <Helmet>
//             <title>Book.tc Portal</title>
//           </Helmet>
//           <Route exact path='/sign-in' component={(props: RouteProps) => { window.location.href = `/sign-in${props.location?.search}`; return null; }} />
//           {contentLoaded && mainContent(permissions)}
//           {!contentLoaded && statusBanner()}

//         </Router>
//       </HelmetProvider>
//     </>
//   );

// }

// export default App;
