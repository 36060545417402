import axios from "axios";
import moment from "moment";
import React, { Fragment, ReactElement, useMemo, useState } from "react";
import { DeepMap, FieldError, FieldValues, Path, UseFormClearErrors, UseFormRegister, UseFormSetValue, UseFormWatch } from "react-hook-form";
import { Redirect } from "react-router-dom";
import { ClipboardCopyIcon } from '@heroicons/react/solid'
import { ListContainer, NameGuidPair } from "./stdlib-models";
import { FormLabelClass } from "./stdlib-styles";

export enum ModalStatus {
    Idle,
    Network,
    Done,
    Error,
    NetworkError
}


export enum PageState {
    NotLoaded,
    Loaded,
    NotFound,
    Saved,
    NeedAuth,
    Forbidden
}

export enum PageStatus {
    Idle,
    Network,
    NetworkError
}


export interface ValidationMessage {
    name: string,
    message: string
}

export interface IValidationResult<T> {
    errors: { name: keyof T, message: string }[]
}

const EmptyContainer: <T>(container: ListContainer<T> | undefined) => boolean = (container) => container === undefined || !container.items || container.items.length === 0;

export const EmptyBladeListMessage: <T>(container: ListContainer<T> | undefined) => JSX.Element = (container) => EmptyContainer(container) ? <div className="blade-empty">Empty</div> : <></>;

export const LoadingAnimation = () => (<svg fill='none' className="w-8 h-8 animate-spin" viewBox="0 0 32 32" xmlns='http://www.w3.org/2000/svg'>
    <path clipRule='evenodd'
        d='M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z'
        fill='currentColor' fillRule='evenodd' />
</svg>);

export const LoadingView = (
    <div className="flex m-8 gap-4 text-gray-500">
        <LoadingAnimation />
        <div>Loading ...</div>
    </div>
)


export const bannerHelper: (pageState: PageState, pageStatus: PageStatus, options?: { hideLoading?: boolean, redirectUrl?: string }) => {
    contentNotLoaded: () => boolean,
    allErrorsBanner: (errors: ValidationMessage[], options?: { hideNames: boolean }) => JSX.Element,
    statusBanner: (errors?: ValidationMessage[]) => JSX.Element
} = (pageState, pageStatus, options) => {
    const Banner: (props: { children: string | JSX.Element[] }) => JSX.Element = (props) => {
        return (<div className="py-8"><div className="mb-2 p-2 bg-red-100 text-red-600">{props.children}</div></div>);
    };

    const statusBanner = (errors?: ValidationMessage[]) => {

        if (pageStatus === PageStatus.Network) {
            return options && options.hideLoading ? <></> : <div className="p-8">{LoadingView}</div>;
        }

        if (pageState === PageState.NeedAuth) {
            const returnUrl = `${encodeURIComponent(window.location.pathname + window.location.search + window.location.hash)}`;
            return <Redirect to={"sign-in" + returnUrl ? `/sign-in?returnUrl=${returnUrl}` : ""} />
        }

        if (errors) {
            return <Banner>{errors.map((x, i) => <div key={i}>{x.name ? x.name + ": " : ""}{x.message}</div>)}</Banner>
        }

        if (pageState === PageState.NotFound) {
            return <Banner>Not found</Banner>;
        }

        if (pageState === PageState.Saved && options && options.redirectUrl) {
            return <Redirect to={options.redirectUrl} />;
        }

        if (pageState === PageState.Saved && !options?.redirectUrl) {
            return (<div className="py-8"><div className="mb-2 p-2 bg-green-100 text-green-600">Changes saved</div></div>);
        }

        if (pageState === PageState.Forbidden) {
            return <Banner>Forbidden - you do not have permission to view this page</Banner>;
        }


        if (pageStatus === PageStatus.NetworkError) {
            return <Banner>Network error</Banner>;
        }

        return <></>;

    }

    return {
        contentNotLoaded: () => pageState !== PageState.Saved && pageState !== PageState.Loaded,
        allErrorsBanner: (errors, opts) => {
            if (!Array.isArray(errors) || errors.length === 0) return <></>;
            const children = errors?.map(x => {
                const message = x.name && !opts?.hideNames ? `${x.name}: ${x.message}` : x.message;
                return (<div key="message">{message}</div>);
            })
            return Banner({ children });
        },
        statusBanner
    };
};

export interface formRowProps<T> {
    key?: string
    property: Path<T>
    required?: boolean
    title: string
    details?: string,
    hideTitle?: boolean
}

export interface fileInputRowProps<T> extends formRowProps<T> {
    accept?: string
}

export interface textBoxProps<T> extends formRowProps<T> {
    placeholder?: string
    type?: string
}

export interface selectBoxProps<T> extends formRowProps<T> {
    options: KeyValuePair[] | NameGuidPair[]
}

export enum TextAreaHeight { h256, h128 }

export interface textAreaProps<T> extends textBoxProps<T> {
    rows?: number
    height?: TextAreaHeight
}

export interface checkBoxRowProps {
    title: string
    children: ReactElement<any, any>[] | ReactElement<any, any>
}

export interface checkBoxListProps<T> {
    title: string
    options: KeyValuePair[]
    property: Path<T>
}


export interface KeyValuePair {
    key: string,
    value: string
}

export const FormTextRow: (props: { title: string, message: any, details?: string, key?: string, defaultMessage?: string }) => JSX.Element = (props) => {
    var message = props.message ?? props.defaultMessage;

    return <FormGroup title={props.title}>
        <div>{message as any}</div>
        {props.details && <div key={props.key} className="text-sm">{props.details}</div> as any}
    </FormGroup>
}


export const FormSubheaderRow: (props: { title: string, button?: any }) => JSX.Element = props => <div className="flex flex-grow-1 flex-wrap items-center justify-between  border-t border-b py-2" >
    <div className={`${FormLabelClass}`}>
        {props.title}
    </div>
    {props.button &&
        <div>
            {props.button}
        </div>}
</div>;


export const FormLinkRow: (props: { title: string, url: string }) => JSX.Element = (props) => {

    const linkGroup = <div className="flex gap-4">
        <a className="link" href={props.url} rel="noreferrer" target="_blank">{props.url}</a>
        <button type="button" onClick={async () => await navigator.clipboard?.writeText(props.url)} className="button-sm inline-flex gap-2"><ClipboardCopyIcon className="h-5 w-5" /> <span>Copy</span></button>

    </div>;


    //return FormTextRow({ title: props.title, message: linkGroup });

    return <FormTextRow title={props.title} message={linkGroup} />
}




export const FormGroup: (props: { title: string, hideTitle?: boolean, for?: string | undefined, children: React.ReactElement<any, any> | React.ReactElement<any, any>[] }) => ReactElement<any, any> = (props) => {
    return (<div className="flex flex-col gap-2">
        {!props.hideTitle && <label className={FormLabelClass} htmlFor={props.for}>{props.title}</label>}
        {props.children}
    </div>);
};

const GetError: (errors: any, property: any) => string = (errors, property) => {
    if (!errors[property]) return "";
    if (errors[property].type === "required") return "This field is required";
    return errors[property].message;
}

export const ErrorMessageWithText: (message: string) => JSX.Element = (message) => <div className="mb-2 p-2 bg-red-100 text-red-600">{message}</div>;

const ErrorMessage: <T>(props: { property: Path<T>, errors: DeepMap<T, FieldError> }) => JSX.Element = (props) => {
    return ((props.errors as any)[props.property]) ? <div className="mb-2 p-2 bg-red-100 text-red-600">{GetError(props.errors, props.property)}</div> : <></>;
};

export const FormGroupWithErrors: <T>(props: { title: string, hideTitle?: boolean, details?: string, property: Path<T>, errors: DeepMap<T, FieldError>, children: React.ReactElement<any, any> }) => ReactElement<any, any> = (props) => {
    return (<FormGroup title={props.title} for={props.title} hideTitle={props.hideTitle}>
        {props.children}
        {props.details ? <div className={FormLabelClass}>{props.details}</div> : <></>}
        {ErrorMessage({ property: props.property, errors: props.errors })}
    </FormGroup>);
};

export interface datePickerProps<T> {
    checkInProperty: keyof T
    checkOutProperty: keyof T
    unavailableDates?: string[]
    title: string
}

export interface DatePickerFormRowElement<T> {
    DatePickerRow: (props: datePickerProps<T>) => ReactElement<any, any>
}

export interface ListTextBoxRowProps<T extends FieldValues> {
    title: string;
    inputType: string,
    pattern?: string,
    property: Path<T>,
    working: boolean,
    watch: UseFormWatch<T>,
    setValue: UseFormSetValue<T>
    errors: DeepMap<T, FieldError>,
    clearErrors: (name?: string | string[]) => void
}

export const ListTextBoxRow: <T extends FieldValues>(props: ListTextBoxRowProps<T>) => JSX.Element = props => {

    const values = (props.watch(props.property) as [] ?? []) as string[];

    const formId = props.property + "addbox";

    const empty = !Array.isArray(values) || values.length === 0;

    const addAction = () => {

        const newValue = (document.getElementById(formId) as any).value as string;
        (document.getElementById(formId) as any).value = "";
        if (!newValue) return;
        if (!empty && values.indexOf(newValue as any) >= 0) return;
        props.clearErrors(props.property);
        props.setValue(props.property, [newValue, ...values] as any, { shouldValidate: true, shouldDirty: true })
    };

    const deleteAction = (item: string) => {
        if (empty) return;
        var newArray = values.filter(x => x !== item);
        props.clearErrors(props.property);
        props.setValue(props.property, newArray as any, { shouldValidate: true, shouldDirty: true })
    }

    return <div className="flex flex-col gap-2">
        <div className={FormLabelClass}>{props.title}</div>
        <div className="flex gap-2">
            <input disabled={props.working} id={formId} type={props.inputType} pattern={props.pattern} className="flex-grow border w-full py-2 px-3 text-grey-darker" /><button type="button" onClick={addAction} className="btnbtn-sm">Add</button>
        </div>
        {ErrorMessage({ property: props.property, errors: props.errors })}
        <div className="border h-32 overflow-y-scroll p-2">
            {empty && <div className="text-gray-200">(empty)</div>}
            {!empty && values.map((x, i) => <div className="flex" key={"item" + i}>
                <div className="flex-grow">{x}</div>
                <button disabled={props.working} type="button" onClick={() => deleteAction(x)} className="btn btn-sm">Delete</button>
            </div>)}

        </div>

    </div>;
}


export interface FormRowElements<T extends FieldValues> {
    TextBoxRow: (props: textBoxProps<T>) => ReactElement<any, any>,
    TimeSpanBoxRow: (props: textBoxProps<T>) => ReactElement<any, any>,
    TextAreaRow: (props: textAreaProps<T>) => ReactElement<any, any>,
    CheckBoxRow: (props: checkBoxRowProps) => ReactElement<any, any>,
    CheckBoxListRow: (props: checkBoxListProps<T>) => ReactElement<any, any>,
    SelectBoxRow: (props: selectBoxProps<T>) => ReactElement<any, any>,
    CheckBox: (props: formRowProps<T>) => ReactElement<any, any>,
    FileInputRow: (props: fileInputRowProps<T>) => ReactElement<any, any>,
    SaveButton: (props: { title?: string }) => JSX.Element
}
//errors: DeepMap<T, FieldError>
export const formRows: <T extends FieldValues>(register: UseFormRegister<T>, errors: any, working: boolean, clearError?: UseFormClearErrors<T>) => FormRowElements<T> = (register, errors, working, clearError) => {
    //const inputClass = "border w-full py-2 px-3 text-grey-darker";
    return {
        TextBoxRow: (props) => {
            let placeholder = "";
            if (props.placeholder) {
                placeholder = props.placeholder;
            } else if (props.required) {
                placeholder = "required";
            } else {
                placeholder = "optional";
            }
            return (<FormGroupWithErrors key={props.key} title={props.title} hideTitle={props.hideTitle} details={props.details} property={props.property} errors={errors}>
                <input type={props.type ?? "text"} placeholder={placeholder} {...register(props.property, { required: props.required })} disabled={working} className="stdlib-textbox" />
            </FormGroupWithErrors>);
        },
        TimeSpanBoxRow: (props) => {
            let placeholder = "";
            if (props.placeholder) {
                placeholder = props.placeholder;
            } else if (props.required) {
                placeholder = "required";
            } else {
                placeholder = "optional";
            }
            return (<FormGroupWithErrors key={props.key} title={props.title} hideTitle={props.hideTitle} details={props.details} property={props.property} errors={errors}>
                <input type="time" pattern="[0-9]{2}:[0-9]{2}" placeholder={placeholder} {...register(props.property, { required: props.required })} disabled={working} className="stdlib-textarea" />
            </FormGroupWithErrors>);
        },
        TextAreaRow: (props) => {
            let placeholder = "";
            if (props.placeholder) {
                placeholder = props.placeholder;
            } else if (props.required) {
                placeholder = "required";
            } else {
                placeholder = "optional";
            }
            let heightClassName = " min-h-textarea-lg";
            if (props.height) {

                switch (props.height) {
                    case TextAreaHeight.h128:
                        heightClassName = " min-h-textarea-sm";
                        break;
                }
            }

            return (<FormGroupWithErrors key={props.key} title={props.title} hideTitle={props.hideTitle} details={props.details} property={props.property} errors={errors}>
                <textarea disabled={working} placeholder={placeholder} className={"stdlib-textarea" + heightClassName} {...register(props.property, { required: props.required })} />
            </FormGroupWithErrors>);
        },
        CheckBoxRow: (props) => {
            return (<FormGroup title={props.title} >
                {props.children}
            </FormGroup>);
        },
        CheckBoxListRow: (props) => {
            const children = props.options.map((x, i) => <label key={x.key} className="stdlib-checkbox" ><input onClick={()=> clearError && clearError(props.property)}  disabled={working} value={x.key} type="checkbox" {...register(((props.property as any) + "." + i) as any)} /> <span className="label">{x.value}</span></label>)
            return (<FormGroupWithErrors title={props.title} errors={errors} property={props.property}>
                <div className="flex flex-col gap-2">
                    {children}
                </div>
            </FormGroupWithErrors>);
        },
        SelectBoxRow: (props) => {
            return (<FormGroupWithErrors key={props.key} title={props.title} hideTitle={props.hideTitle} details={props.details} property={props.property} errors={errors}>
                <select className="stdlib-select" disabled={working} {...register(props.property, { required: props.required })} >
                    {props.options?.map((o: any) => <option key={o.key ?? o.guid} value={o.key ?? o.guid}>{o.value ?? o.name}</option>)}
                </select>
            </FormGroupWithErrors>);
        },
        CheckBox: (props) => {
            return (<div className="block">
                <label className="stdlib-checkbox" ><input disabled={working} type="checkbox" {...register(props.property, { required: props.required })} /> <span className="label">{props.title}</span></label>
                {props.details && <div className={`ml-6 ${FormLabelClass}`}>{props.details}</div>}
                {ErrorMessage({ property: props.property, errors })}
            </div>);
        },
        FileInputRow: (props) => {
            return (<FormGroupWithErrors key={props.key} title={props.title} hideTitle={props.hideTitle} details={props.details} property={props.property} errors={errors}>
                <input type="file" accept={props.accept} disabled={working} {...register(props.property, { required: props.required })} />
            </FormGroupWithErrors>);

        },
        SaveButton: (props) => <div className="flex pt-4 gap-4"><button disabled={working} className="button">{props.title ?? "Save"}</button> {working && <LoadingAnimation />}</div>
    };


};


export function callGetApi<T>(apiUrl: string, setPageState: (state: PageState) => void, setPageStatus: (status: PageStatus) => void, setData: (data: T) => void, setError?: (name: keyof T, options: setErrorOptions) => void) {//: ()=> void { //EffectCallback {//: undefined | (()=> void) {
    setPageStatus(PageStatus.Network);
    axios.get<T>(apiUrl)
        .then(response => {
            setData(response.data);
            setPageState(PageState.Loaded);
            setPageStatus(PageStatus.Idle);
        })
        .catch(err => {
            setPageStatus(PageStatus.Idle);
            const statusCode: number = err.response?.status ?? 500;
            if (statusCode === 400 && setError && err.response.data && err.response.data.errors) {
                var errArray = err.response.data as IValidationResult<T>;
                errArray.errors.forEach(err => setError(err.name, { type: "server", message: err.message }));
            } else if (statusCode === 404) {
                setPageState(PageState.NotFound);
            }
            else if (statusCode === 403) {
                setPageState(PageState.Forbidden);
            } else if (statusCode === 401) {
                //auth
                setPageState(PageState.NeedAuth);
            } else {
                //connection
                setPageStatus(PageStatus.NetworkError);
            }
        });
}

interface setErrorOptions {
    type: string,
    message: string
}

export const checkBoxListDataPreProcess: (data: string[], options: Array<KeyValuePair>) => string[] = (data, options) => {
    //data = [a,d]
    //options= [a,b,c,d,e]
    if (!Array.isArray(data) || !Array.isArray(options)) return data;
    return options.map(x => data.includes(x.key) ? x.key : false) as any;
}

export const checkBoxListDataPostProcessToArray: (data: string[]) => string[] = (data) => {
    if (!Array.isArray(data)) return data;
    return data.filter(x => !!x);
}

export const checkBoxListDataPostProcessToString: (data: string[]) => string = (data) => {
    var options = checkBoxListDataPostProcessToArray(data);
    if (!options) return "";
    return options.join(";");
}


export function useErrorStore<T>(): { errors: ValidationMessage[], setError: (name: keyof T, options: setErrorOptions) => void, clearErrors: () => void } {
    const [errors, setErrorArray] = useState<ValidationMessage[]>([] as ValidationMessage[])
    const setError = useMemo(() => (name: keyof T, options: setErrorOptions) => {
        const newArray: ValidationMessage[] = [];
        if (errors) {
            errors.forEach(x => {
                if (x.name !== name) newArray.push(x);
            })
        }
        newArray.push({ name: name as string, message: options.message });
        setErrorArray(newArray);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setErrorArray]); //do not include errors here or useeffect will be called repeatedly
    const clearErrors = () => setErrorArray([]);
    return { errors, setError, clearErrors };
}

export function callPostApi<T, T2>(apiUrl: string, model: T, setPageState: (state: PageState) => void, setPageStatus: (status: PageStatus) => void, setError: (name: keyof T, options: setErrorOptions) => void, setData?: (data: T2) => void) {
    setPageStatus(PageStatus.Network);
    axios.post(apiUrl, model)
        .then(response => {
            setPageStatus(PageStatus.Idle);
            setPageState(PageState.Saved);
            if (setData) {
                setData(response.data);
            }
        })
        .catch(err => {
            setPageStatus(PageStatus.Idle);
            const statusCode: number = err.response?.status ?? 500;
            if (statusCode === 400 && err.response.data && err.response.data.errors) {
                var errArray = err.response.data as IValidationResult<T>;
                errArray.errors.forEach(err => setError(err.name, { type: "server", message: err.message }));
            } else if (statusCode === 401) {
                setPageState(PageState.NeedAuth);
            } else if (statusCode === 403) {
                setPageState(PageState.Forbidden);
            } else if (statusCode === 404) {
                setPageState(PageState.NotFound)
            } else {
                //other type of error
                setPageStatus(PageStatus.NetworkError);
            }
        });
}

export function friendlyFormatFromNowDate(date: string) {
    var mom = moment(date);
    return mom.fromNow();
}

export function friendlyFormatDateOnly(date: string) {
    var mom = moment(date);
    return mom.format("DD/MMM/YY");
}



export interface ReloadContainerChildProps {
    reloadSelf: () => void;
}


export interface ReloadContainerProps {
    children: React.FC<ReloadContainerChildProps>;
}

export function ReloadContainer(props: ReloadContainerProps) {
    const [reloadLevel, setReloadLevel] = useState<number>(0);
    const Child = props.children;
    return <Fragment >
        <Child reloadSelf={() => setReloadLevel(reloadLevel + 1)} key={reloadLevel} />
    </Fragment>;
}

export function findByGuid(list: { [key: string]: string; } | undefined, guid: string) {
    if (!guid) return "(unknown)";
    if (!list || !list[guid]) return guid;
    return list[guid];
}
