import { Fragment, useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { bannerHelper, callGetApi, LoadingAnimation, PageState, PageStatus, useErrorStore } from "./stdlib-form";
import { ArrowCircleLeftIcon } from '@heroicons/react/outline'
import { FormLabelClass, TitleLabelClass } from "./stdlib-styles";
import { ActionButton, ActionItem } from "./stdlib-action";
import { Items } from "react-virtuoso/dist/List";



export interface PanelHeaderProps {
    title: string;
    disabled?: boolean;
    loading?: boolean;
    buttons?: ActionItem[] | undefined
    backButtonAction?: () => void
    backButtonVisible?: boolean
    content?: JSX.Element

}

export const PanelHeader = (props: PanelHeaderProps) => <div className="flex-0 flex flex-row flex-wrap items-center justify-center md:justify-between gap-4 p-4">
    <div className="text-xl flex align-items-end">
        {props.backButtonVisible &&
            <button className="hover:text-gray-500" onClick={props.backButtonAction}><ArrowCircleLeftIcon className="w-6 h-6 mr-2" /></button>}
        <span className={TitleLabelClass}>{props.title}</span>
        {props.loading && <LoadingAnimation />}
    </div>
    
    <div className="flex flex-wrap gap-1 bg-white rounded p-2">
        {props.buttons && props.buttons.map((x: any, i) => {
            if (x.action) {
                return <button className="btn btn-sm" key={i} disabled={x.disabled} onClick={x.action} >{x.title}</button>;
            } else if (x.to) {
                return <Link className="btn btn-sm" key={i} to={x.to}>{x.title}</Link>
            } else if (x.href) {
                return <a className="btn btn-sm" key={i} href={x.href}>{x.title}</a>
            } else if (x.gap) {
                return <div key={i} className={`inline-block h-2 w-${x.gap}`}></div>;
            }
            return <></>;
        })}
        {props.content && props.content}
    </div>
</div>;


export interface PanelFooterProps {
    okTitle?: string;
    okAction?: () => void;
    okHidden?: boolean;
    okFormId?: string;
    okDisabled?: boolean;

    cancelTitle?: string,
    cancelAction?: () => void,
    cancelHidden?: boolean,

    working?: boolean
}

export const PanelFooter = (props: PanelFooterProps) => {


    // let okButtonProps: any = {
    //     className: "button button-primary button-lg"
    // };
    // let cancelButtonProps: any = {
    //     onClick: props.cancelAction,
    //     className: "button button-lg"
    // }

    // if (props.working) {
    //     okButtonProps = {
    //         disabled: "disabled",
    //         ...okButtonProps
    //     };
    //     cancelButtonProps = {
    //         disabled: "disabled",
    //         ...cancelButtonProps
    //     };
    // }

    // if (props.okDisabled) {
    //     okButtonProps = {
    //         disabled: "disabled",
    //         ...okButtonProps
    //     }
    // }

    // if (props.okAction) {
    //     okButtonProps = {
    //         onClick: props.okAction,
    //         ...okButtonProps
    //     };
    // } else if (props.okFormId) {
    //     okButtonProps = {
    //         type: "submit",
    //         form: props.okFormId,
    //         ...okButtonProps
    //     };
    // }

    const actionItems: ActionItem[] = [];
    if (!props.okHidden) {
        if (props.okAction) {
            actionItems.push({
                kind: "button",
                title: props.okTitle ?? "Ok",
                action: props.okAction,
                disabled: props.working,
                className: "btn btn-primary"
            })
        } else if (props.okFormId) {
            actionItems.push({
                kind: "button",
                title: props.okTitle ?? "Ok",
                type: "submit",
                formId: props.okFormId,
                disabled: props.working,
                className: "btn btn-primary"
            })

        }
    }
    if (!props.cancelHidden) {
        if (props.cancelAction) {
            actionItems.push({
                kind: "button",
                title: props.cancelTitle ?? "Cancel",
                action: props.cancelAction,
                disabled: props.working,
                className: "btn"
            })
        }
    }

    // const renderOptions: ActionItemRenderOptions = {


    // };

    // { !props.okHidden && <button {...okButtonProps} >{props.okTitle ?? "Ok"}</button> }
    // { !props.cancelHidden && <button {...cancelButtonProps}>{props.cancelTitle ?? "Cancel"}</button> }


    return <div className="flex-grow-0 flex gap-2 justify-end p-4">
        {RenderActionItems(actionItems)}
    </div>
};


export interface PageLayoutProps {
    title: string;
    children: Array<JSX.Element | string> | JSX.Element | string
    modals?: JSX.Element | Array<JSX.Element>;
    footer: PanelFooterProps;
}

export enum PanelWidth { Narrow, Wide }

export const WidthEnumToClass: (width: PanelWidth | undefined) => string = (width) => {
    if (!width) return "max-w-xl";
    const w = width as PanelWidth;
    switch (w) {
        case PanelWidth.Narrow:
            return "lg:flex-grow-0 lg:w-80";
        case PanelWidth.Wide:
            return "lg:flex-grow-0 lg:w-screen-1/2";
        default:
            return "max-w-xl";
    }
}


export const PanelBackgroundStyle = "bg-white flex-grow border border-gray-300 md:m-4";

export const PanelBackground = (props: { children: any, width?: PanelWidth }) => {

    let widthClass = WidthEnumToClass(props.width);
    // if (props.width === PanelWidth.Narrow) {
    //     widthClass = "lg:flex-grow-0 lg:w-80";

    // } else if (props.width === PanelWidth.Wide) {
    //     widthClass = "lg:flex-grow-0 lg:w-screen-1/2";
    // }

    return <div className={"bg-white flex-grow border border-gray-300 md:m-4 flex flex-col " + widthClass} >
        {props.children}
    </div >;
}

export const PanelLayout = (props: PageLayoutProps) => <><PanelHeader title={props.title} />
    <div className="flex-grow overflow-y-scroll">
        <div className="p-4">
            {props.children}
        </div>
    </div>
    {props.modals}
    <PanelFooter {...props.footer} />
</>;

export const PanelScrollContainer = (props: { children: any }) => <div className="flex-grow overflow-y-scroll overflow-x-hidden flex flex-col p-4" >{props.children}</div>;

export interface LoadContainerProps<T, T2> {
    dataUrl: string | undefined,
    childProps: T2,
    child: React.FC<{ data: T } & T2>
    reloadCount?: number
}

export function LoadContainer<T, T2>(props: LoadContainerProps<T, T2>) {
    const [state, setState] = useState<PageState>(PageState.NotLoaded);
    const [status, setStatus] = useState<PageStatus>(PageStatus.Idle);
    const [data, setData] = useState<T>();
    const { setError, errors } = useErrorStore<T>();
    //const [reloadLevel, setReloadLevel] = useState<number>(props.)
    const { statusBanner, contentNotLoaded } = bannerHelper(state, status);

    const defaultView = <></>;

    useEffect(() => {
        if (!props.dataUrl) return;
        callGetApi<T>(props.dataUrl, setState, setStatus, setData, setError)
    }, [props.dataUrl, props.reloadCount, setError]);


    if (!props.dataUrl) return defaultView;

    if (contentNotLoaded()) {
        return statusBanner(errors);
    }


    if (!data) return defaultView;

    return <props.child data={data} {...props.childProps} />
}

export const PriceTable = (props: { title?: string, total?: number, totalDescription?: string, entries?: { description: string, amount: number, negative?: boolean }[], hideZeroEntries?: boolean, hideIfAllZeros?: boolean }) => {

    if (props.hideIfAllZeros && !props.total) {
        if (!props.entries || !Array.isArray(props.entries)) return <></>;
        if (!props.entries.some((x) => x.amount > 0)) return <></>;
    }

    if (props.total === 0 && !props.entries && props.hideIfAllZeros) {
        return <></>;
    }

    const filteredEntries = props.hideZeroEntries ? props.entries?.filter(x => x.amount > 0) : props.entries;
    return <div className="flex flex-col text-xs gap-2">
        {props.title && <div className={FormLabelClass + " "}>{props.title}</div>}
        <div className="border">
            {filteredEntries && filteredEntries.map((x, i) =>
                <div className="flex gap-2" key={i}>
                    <div className="flex-1">{x.description}</div>
                    <div className="flex-0">{x.negative && '-'}${x.amount.toFixed(2)}</div>
                </div>
            )}
            {props.total && props.total > 0 &&
                <div className="flex gap-2 font-bold border-t border-b ">
                    <div className="flex-1">{props.totalDescription ?? "Total"}</div>
                    <div className="flex-0">${props.total.toFixed(2)}</div>
                </div>}
        </div>
    </div>;
}

export enum FormTextRowFormat { Text, Email, Phone }

export interface FormTextRowProps {
    title: string;
    message: string | number | string[] | JSX.Element | JSX.Element[];
    hideOnEmptyMessage?: boolean;
    defaultMessage?: string;
    details?: string;
    key?: any
    format?: FormTextRowFormat,
    editButtonAction?: () => void | undefined,
    editButtonTitle?: string,
    editButtonStyle?: string
}

export const FormTextRow2 = (props: FormTextRowProps) => {
    if (props.hideOnEmptyMessage && !props.message) {
        return <></>;
    }


    let messageContent: any = props.message;
    let flexDir = "";
    if (Array.isArray(props.message)) {
        messageContent = <div className="flex flex-col">{props.message.map((x, i) => <div key={i}>{x}</div>)}</div>
        flexDir = "flex-col-reverse"
    } else if (props.message && props.format) {
        switch (props.format) {
            case FormTextRowFormat.Email:
                messageContent = <a className="link" href={`mailto:${props.message}`}>{props.message}</a>;
                break
            case FormTextRowFormat.Phone:
                messageContent = <a className="link" href={`tel:${props.message}`}>{props.message}</a>;
                break
        }
    } else if (!props.message && props.defaultMessage) {
        messageContent = props.defaultMessage;
    }



    return <div key={props.key} className="flex justify-between flex-wrap">
        <div className={`flex-0 ${FormLabelClass} `}>
            {props.title}
        </div>
        <div className="flex-0 flex flex-col gap-1 items-end">
            <div className={`flex gap-1 ${flexDir}`}>
                <span className="ml-2">{messageContent}</span>
                {props.editButtonAction && <button className={props.editButtonStyle ?? "btn btn-xs"} onClick={props.editButtonAction}>{props.editButtonTitle ?? "Edit"}</button>}
            </div>
            {props.details && <span className="text-xs">{props.details}</span>}
        </div>
    </div>;
};


export interface FormLogRowProps<T> {
    title: string,
    items: T[],
    row: (item: T, index: number) => JSX.Element,
    hideIfEmpty?: boolean,
}

export const FormLogRow: <T>(props: FormLogRowProps<T>) => JSX.Element = props => {
    if ((!props.items || props.items.length === 0) && props.hideIfEmpty) {
        return <></>;
    }

    return <div className="flex flex-col">
        <div className={`flex-0 ${FormLabelClass} mb-2`}>
            {props.title}
        </div>
        <div className="flex-0 flex flex-col">
            {!props.items && <div className="text-gray-800 text-sm">(nothing to show)</div>}
            {props.items && props.items.map((x, i) => props.row(x, i))}
        </div>
    </div>;

}

export const ButtonGroup: (props: { children: any }) => JSX.Element = (props) => <div className="flex flex-row flex-wrap gap-2">{props.children}</div>


export const alertStyle = "rounded-sm flex flex-wrap md:flex-nowrap gap-2 p-2 bg-gray-100 justify-between";
export const actionButtonClass = "btn btn-lg whitespace-nowrap";
export const statusItem = (title: string, details: string, buttonTitle?: string, buttonAction?: () => void) => <div className={`${alertStyle} items-center`}>
    <div className="flex flex-col gap-2" >
        <div className={`${TitleLabelClass} text-gray-700`}>{title}</div>
        {details && <div className={`${FormLabelClass} text-gray-700`}>{details}</div>}
    </div>
    {buttonTitle && <div className="md:flex-0">
        <button className={actionButtonClass} onClick={buttonAction} >{buttonTitle}</button>
    </div>}
</div>

export interface ActionItemRenderOptions {
    buttonClassName?: string;
    linkActiveClassName?: string;
    linkClassName?: string;
    hrefClassName?: string;
    shouldCloseMenu?: boolean;
}

export type ActionItemClickHandler = (item: ActionItem) => void


export const RenderActionItem: (item: ActionItem, key: number, options?: ActionItemRenderOptions, onClick?: ActionItemClickHandler) => JSX.Element = (item, key, options, onClick) => {

    if (item.kind === "link") {


        return <NavLink onClick={() => onClick && onClick(item)} key={key} activeClassName={item.className ?? options?.linkActiveClassName} className={options?.linkClassName} to={item.to}><div>{item.title}{item.badge === "new" && <div>NEW</div>}</div></NavLink>
    }

    if (item.kind === "button") {
        return <button key={key} type={item.type ?? "button"} form={item.formId} className={item.className ?? options?.buttonClassName} onClick={() => {
            if (onClick) onClick(item);
            if (item.action) item.action();
        }} disabled={item.disabled}>{item.title}</button>
    }

    if (item.kind === "href") {
        return <a key={key} className={item.className ?? options?.hrefClassName} href={item.href}>{item.title}</a>
    }


    return <></>;
}

export const RenderActionItems: (items: ActionItem[], options?: ActionItemRenderOptions, onClick?: ActionItemClickHandler) => JSX.Element = (items, options, onClick) => {
    return <>
        {Array.isArray(items) && items.map((x, i) => RenderActionItem(x, i, options, onClick))}
    </>;
}

export const EmptyPage = () => {
    return (<div className="text-xl text-center text-gray-400 p-8">Choose a page from the menu</div>);
}